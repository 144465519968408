//
// Callouts
//

.bd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: .25rem;
  border-radius: .25rem;
}

.bd-callout h4 {
  margin-top: 0;
  margin-bottom: .25rem;
}

.bd-callout p:last-child {
  margin-bottom: 0;
}

.bd-callout code {
  border-radius: .25rem;
}

.bd-callout + .bd-callout {
  margin-top: -.25rem;
}

// Variations
@mixin bs-callout-variant($color) {
  border-left-color: $color;

  h4 { color: $color; }
}

.bd-callout-info { @include bs-callout-variant($bd-info); }
.bd-callout-warning { @include bs-callout-variant($bd-warning); }
.bd-callout-danger { @include bs-callout-variant($bd-danger); }
